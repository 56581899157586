

export default {
  name: 'VFooter-services',
  computed: {
    cssProps() {
      return {
        '--bg-color': this.layServiceBg,
        '--txt-color': this.layServiceTxcolor
      }
    }
  },
  props: {
    hasOther: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default() {}
    },
    bgColors: {
      type: String,
      default: '#FFFFFF'
    },
    txtColors: {
      type: String,
      default: '#000000'
    },
    layServiceBg: {
      type: String,
      default: '#fafafa'
    },
    layServiceTxcolor: {
      type: String,
      default: '#0d153d'
    }
  },
  components: {
    Wrapper: () => import('@/components/Structure/Wrapper'),
  },
  data() {
    return {
      swiperOption: {
        initialSlide: 0,
        direction: 'horizontal',
        loop: false,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
        breakpointsInverse: true,
        lazy: false,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          550: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            spaceBetween: 10
          },
          720: {
            slidesPerView: 3,
            slidesPerGroup: 1,
            spaceBetween: 10
          },
          992: {
            slidesPerView: 4,
            slidesPerGroup: 1,
            spaceBetween: 20
          }
        }
      }
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.handleMobile)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleMobile)
  },
  async mounted() {
    await this.setImagesDimensions()
    if (this.content.services.length >= 5) {
      this.swiperOption.loop = true
    }
    if (window.innerWidth <= 992) {
      this.swiperOption.loop = true
    }
  },
  methods: {
    handleMobile() {
      if (window.innerWidth <= 992) {
        this.swiperOption.loop = true
      }
    },
    removeHtmlTags(str) {
      return str.replace(/<\/?[^>]+(>|$)/g, "");
    },
    async setImagesDimensions() {
    try {
      const images = document.querySelectorAll('.services__item__icon img');
      images.forEach(img => {
        img.width = img.naturalWidth
        img.height = img.naturalHeight
      })
    } catch (error) {
    console.error('Erro ao definir as dimensões das imagens:', error)
    }
    }
  }
}
